import { useEffect, useState } from "react";
import { PrimaryButton, TextInput } from "../../components";

import "./newsletter.scss";
import { httpsCallable } from "firebase/functions";
import { functions } from "../../firebase";
import { FirebaseError } from "firebase/app";

const NewsLetter = () => {
  const [letter, setLetter] = useState("");
  const [title, setTitle] = useState("");
  const [desc, setDesc] = useState("");

  const [email, setEmail] = useState("");
  const [userId, setUserId] = useState("");
  const [feedback, setFeedback] = useState<string>();

  const [filter, setFilter] = useState<"organisations" | "users">();

  useEffect(() => {
    setTimeout(() => {
      setFeedback(undefined);
    }, 5000);
  }, [feedback]);

  return (
    <>
      <section className="part-page">
        <div id="main-word">
          <h2>Éditeur de Newsletter</h2>
        </div>
      </section>
      <section className="part-page newsletter-editor">
        <TextInput
          type="textarea"
          value={letter}
          onChange={(val) => setLetter(val)}
          placeholder="Contenu"
        />
        <TextInput
          value={title}
          onChange={(val) => setTitle(val)}
          placeholder="Titre"
        />
        <TextInput
          value={desc}
          onChange={(val) => setDesc(val)}
          placeholder="Description"
        />
        <div id="target-filter">
          <div className="choice" key={"orga"}>
            <input
              type="radio"
              id="orga"
              name="Organisations"
              value="Organisations"
              checked={filter === "organisations"}
              onChange={() => {
                setFilter("organisations");
              }}
            />
            <label htmlFor="orga">Organisations</label>
          </div>
          <div className="choice" key={"users"}>
            <input
              type="radio"
              id="users"
              name="Users"
              value="Users"
              checked={filter === "users"}
              onChange={() => {
                setFilter("users");
              }}
            />
            <label htmlFor="users">Users</label>
          </div>
          <div className="choice" key={"both"}>
            <input
              type="radio"
              id="both"
              name="Les deux"
              value="Both"
              checked={filter === undefined}
              onChange={() => {
                setFilter(undefined);
              }}
            />
            <label htmlFor="both">Les deux</label>
          </div>
        </div>
        <TextInput
          value={email}
          onChange={(val) => setEmail(val)}
          placeholder="Email-test"
        />
        <TextInput
          value={userId}
          onChange={(val) => setUserId(val)}
          placeholder="UserId-test"
        />
        <PrimaryButton
          onClick={async () => {
            try {
              await httpsCallable(
                functions,
                "newsletter-sendTest"
              )({
                letter,
                email,
                title,
                desc,
                userId,
                filter,
              });
              setFeedback("Message test bien envoyé !");
            } catch (err) {
              if (err instanceof FirebaseError) {
                setFeedback(err.message);
              }
            }
          }}
          icon="📬"
        >
          Envoyer Mail Test
        </PrimaryButton>
        <PrimaryButton
          onClick={async () => {
            try {
              await httpsCallable(
                functions,
                "newsletter-send"
              )({
                letter,
                title,
                desc,
                filter,
              });
              setFeedback("Newsletter envoyée !");
            } catch (err) {
              if (err instanceof FirebaseError) {
                setFeedback(err.message);
              }
            }
          }}
          icon="📬"
        >
          Envoyer à tous
        </PrimaryButton>
        {feedback && <h3>{feedback}</h3>}
      </section>
    </>
  );
};

export default NewsLetter;
