import { useMutation } from "@tanstack/react-query";
import { httpsCallable } from "firebase/functions";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { functions } from "../../firebase";
import { Loading } from "../../components";

const Unsubscribe = () => {
  const { userId } = useParams();
  const { mutate, isPending, isSuccess, error } = useMutation({
    mutationKey: ["newsletter-unsubscribe"],
    mutationFn: async (id?: string) => {
      await httpsCallable(
        functions,
        "newsletter-unsubscribe"
      )({
        userId: id,
      });
    },
  });

  useEffect(() => {
    mutate(userId);
  }, [mutate, userId]);

  return (
    <section className="part-page">
      {isPending ? (
        <Loading />
      ) : isSuccess ? (
        <h2>Tu a bien été supprimé de la newsletter</h2>
      ) : error !== null ? (
        <>
          <h2>Une erreur est survenue</h2>
          <p>{error.message}</p>
        </>
      ) : (
        <></>
      )}
    </section>
  );
};

export default Unsubscribe;
