import { useCallback, useEffect, useState } from "react";
import { Loading, PrimaryButton, TextInput } from "../../../components";

import "./survey.scss";
import { useCurrentUser } from "../../../hooks";
import { useNavigate } from "react-router-dom";
import { httpsCallable } from "firebase/functions";
import { db, functions } from "../../../firebase";
import { FirebaseError } from "firebase/app";
import { SurveyIds } from "../../../hooks/useUser";
import { useMutation } from "@tanstack/react-query";
import { doc, getDoc } from "firebase/firestore";

type QuestionTypes =
  | "text"
  | "yes-no"
  | "rating"
  | "single-choice"
  | "multiple-choices";

export type Question<T = QuestionTypes> = {
  id: string;
  type: T;
  description: string;
  choices?: string[];
  mandatory?: boolean;
  subQuestion?: (value: any) => Question | undefined;
};

interface QuestionContentProps<T> {
  value: T;
  setValue: (value: T) => void;
}

interface ChoicedQuestionContentProps<T> extends QuestionContentProps<T> {
  choices: string[];
}

const TextQuestionContent = ({
  value,
  setValue,
}: QuestionContentProps<string>) => {
  return <TextInput value={value ?? ""} onChange={(val) => setValue(val)} />;
};

const YesNoQuestionContent = ({
  value,
  setValue,
}: QuestionContentProps<boolean>) => {
  return (
    <div className="yes-no-question">
      <div className="choice">
        <input
          type="checkbox"
          id="yes"
          name="yes"
          value="yes"
          checked={value === true}
          onChange={() => setValue(true)}
        />
        <label htmlFor="yes">Oui</label>
      </div>
      <div className="choice">
        <input
          type="checkbox"
          id="no"
          name="no"
          value="no"
          checked={value === false}
          onChange={() => setValue(false)}
        />
        <label htmlFor="no">Non</label>
      </div>
    </div>
  );
};

const RatingQuestionContent = ({
  value,
  setValue,
}: QuestionContentProps<
  0 | 0.5 | 1 | 1.5 | 2 | 2.5 | 3 | 3.5 | 4 | 4.5 | 5
>) => {
  return (
    <div className="question-rating">
      <div
        className={`rating-star ${
          value >= 1 ? "star-full" : value >= 0.5 ? "star-half" : ""
        }`}
        onClick={(e) => {
          const star = e.currentTarget.getBoundingClientRect();
          if (e.clientX >= star.x && e.clientX < star.x + star.width / 4) {
            setValue(0);
          } else if (
            e.clientX >= star.x &&
            e.clientX < star.x + star.width / 2
          ) {
            setValue(0.5);
          } else if (
            e.clientX >= star.x + star.width / 2 &&
            e.clientX <= star.x + star.width
          ) {
            setValue(1);
          }
        }}
      ></div>
      <div
        className={`rating-star ${
          value >= 2 ? "star-full" : value >= 1.5 ? "star-half" : ""
        }`}
        onClick={(e) => {
          const star = e.currentTarget.getBoundingClientRect();
          if (e.clientX >= star.x && e.clientX < star.x + star.width / 2) {
            setValue(1.5);
          } else if (
            e.clientX >= star.x + star.width / 2 &&
            e.clientX <= star.x + star.width
          ) {
            setValue(2);
          }
        }}
      ></div>
      <div
        className={`rating-star ${
          value >= 3 ? "star-full" : value >= 2.5 ? "star-half" : ""
        }`}
        onClick={(e) => {
          const star = e.currentTarget.getBoundingClientRect();
          if (e.clientX >= star.x && e.clientX < star.x + star.width / 2) {
            setValue(2.5);
          } else if (
            e.clientX >= star.x + star.width / 2 &&
            e.clientX <= star.x + star.width
          ) {
            setValue(3);
          }
        }}
      ></div>
      <div
        className={`rating-star ${
          value >= 4 ? "star-full" : value >= 3.5 ? "star-half" : ""
        }`}
        onClick={(e) => {
          const star = e.currentTarget.getBoundingClientRect();
          if (e.clientX >= star.x && e.clientX < star.x + star.width / 2) {
            setValue(3.5);
          } else if (
            e.clientX >= star.x + star.width / 2 &&
            e.clientX <= star.x + star.width
          ) {
            setValue(4);
          }
        }}
      ></div>
      <div
        className={`rating-star ${
          value >= 5 ? "star-full" : value >= 4.5 ? "star-half" : ""
        }`}
        onClick={(e) => {
          const star = e.currentTarget.getBoundingClientRect();
          if (e.clientX >= star.x && e.clientX < star.x + star.width / 2) {
            setValue(4.5);
          } else if (
            e.clientX >= star.x + star.width / 2 &&
            e.clientX <= star.x + star.width
          ) {
            setValue(5);
          }
        }}
      ></div>
    </div>
  );
};

const SingleChoiceQuestionContent = ({
  choices,
  value,
  setValue,
}: ChoicedQuestionContentProps<string>) => {
  function onChange(choice: string) {
    setValue(choice);
  }

  return (
    <div className="question-choices">
      {choices?.map((choice) => (
        <div className="choice" key={choice} onClick={() => onChange(choice)}>
          <input
            type="radio"
            id={choice}
            name={choice}
            value={choice}
            checked={value === choice}
            onChange={() => onChange(choice)}
          />
          <label htmlFor={choice}>{choice}</label>
        </div>
      ))}
    </div>
  );
};

const MultipleChoicesQuestionContent = ({
  choices,
  value,
  setValue,
}: ChoicedQuestionContentProps<string[]>) => {
  function onChange(choice: string) {
    const newList = [...(value ?? [])];
    if (!newList.includes(choice)) {
      newList.push(choice);
    } else {
      newList.splice(newList.indexOf(choice), 1);
    }

    if (newList !== value) {
      setValue(newList);
    }
  }

  return (
    <div className="question-choices">
      {choices?.map((choice) => (
        <div className="choice" key={choice} onClick={() => onChange(choice)}>
          <input
            type="checkbox"
            id={choice}
            name={choice}
            value={choice}
            checked={value?.includes(choice) ?? false}
            onChange={() => onChange(choice)}
          />
          <label htmlFor={choice}>{choice}</label>
        </div>
      ))}
    </div>
  );
};

interface QuestionProps {
  question: Question;
  onChange: (value: any) => void;
  subQuestion?: boolean;
}

const QuestionComponent = ({
  question,
  onChange,
  subQuestion,
}: QuestionProps) => {
  const [value, setValue] = useState<any>();

  const changeValue = useCallback(
    (value: any) => {
      setValue(value);
      onChange(value);
    },
    [onChange]
  );

  const getQuestionContent = useCallback(
    (value: any) => {
      switch (question.type) {
        case "text":
          return (
            <TextQuestionContent
              value={value}
              setValue={(val) => changeValue(val)}
            />
          );
        case "yes-no":
          return (
            <YesNoQuestionContent
              value={value}
              setValue={(val) => changeValue(val)}
            />
          );
        case "rating":
          return (
            <RatingQuestionContent
              value={value}
              setValue={(val) => changeValue(val)}
            />
          );
        case "single-choice":
          return (
            <SingleChoiceQuestionContent
              choices={question.choices!}
              value={value}
              setValue={(val) => changeValue(val)}
            />
          );
        case "multiple-choices":
          return (
            <MultipleChoicesQuestionContent
              choices={question.choices!}
              value={value}
              setValue={(val) => changeValue(val)}
            />
          );
      }
    },
    [question.type, question.choices, changeValue]
  );

  return (
    <div className={`survey-question ${question.mandatory ? "mandatory" : ""}`}>
      {subQuestion ? (
        <h3>{question.description}</h3>
      ) : (
        <h2>{question.description}</h2>
      )}
      <div id="question-content">{getQuestionContent(value)}</div>
      {question.subQuestion && question.subQuestion(value) ? (
        <QuestionComponent
          question={question.subQuestion(value)!}
          onChange={(subValue) => {
            onChange({
              value,
              subValue,
            });
          }}
          subQuestion
        />
      ) : (
        <></>
      )}
    </div>
  );
};

interface SurveyProps {
  id: SurveyIds;
  questions: Question[];
}

type ValuesType = Record<string, { value: any; subValue?: any } | any>;

const Survey = ({ id, questions }: SurveyProps) => {
  const [values, setValues] = useState<ValuesType>({});
  const nav = useNavigate();

  const [bonusAmount, setBonusAmount] = useState<number>();

  const [hasSumitted, setHasSubmitted] = useState(false);
  const [error, setError] = useState<string>();
  const [forbidden, setForbidden] = useState<string>();
  const currentUser = useCurrentUser();

  const { mutate: submit, isPending } = useMutation({
    mutationFn: async () => {
      setError(undefined);
      console.log(values);
      if (
        questions.some(
          (question) =>
            question.mandatory &&
            (values[question.id] === undefined ||
              (question.subQuestion?.(values[question.id]) !== undefined &&
                values[question.id]?.subValue === undefined))
        )
      ) {
        setError("Des réponses sont manquantes !");
        return;
      }
      try {
        await httpsCallable(
          functions,
          "users-submitSurvey"
        )({
          surveyId: id,
          userId: currentUser.data?.uuid,
          responses: values,
        });
      } catch (err) {
        if (err instanceof FirebaseError) {
          if (err.message.includes("unauthorized")) {
            setError("Connecte-toi pour répondre aux sondages");
            return;
          }
          if (err.message.includes("missing fields")) {
            setError("Des réponses sont manquantes !");
            return;
          }
          if (err.message.includes("already answered")) {
            setError("Tu as déjà répondu à ce sondage !");
            return;
          }
          setError(
            "Une erreur est survenue, essaye à nouveau ou contacte nous !"
          );
        }
        return;
      }

      setHasSubmitted(true);
    },
  });

  useEffect(() => {
    async function fetch() {
      const docRes = await getDoc(doc(db, `Consts/public_amounts`));
      setBonusAmount(docRes.data()?.bonusAmount);
    }

    fetch();
  }, []);

  useEffect(() => {
    if ((currentUser.data?.surveys ?? {})[id] === true) {
      setHasSubmitted(true);
    }

    if (currentUser.data === undefined) {
      return;
    }

    if ((currentUser.data?.pastTrips ?? []).length > 0 && id === "pre-use") {
      setForbidden(
        "Ce questionnaire est réservé aux membres n'ayant pas encore effectué de trajets."
      );
    }

    if ((currentUser.data?.pastTrips ?? []).length === 0 && id === "post-use") {
      setForbidden(
        "Ce questionnaire est réservé aux membres ayant déjà effectué un trajet."
      );
    }
  }, [currentUser.data, id]);

  const updateValues = useCallback(
    (id: string, value: any | { value: any; subValue?: any }) => {
      setValues((prev) => {
        const newMap = { ...prev };
        newMap[id] = value;
        return newMap;
      });
    },
    []
  );

  return (
    <>
      <section className="part-page" id="survey-header">
        <div id="main-word">
          <h2>
            <span className="highlighted">Aide </span>à{" "}
            <span className="highlighted">améliorer</span> l'app,
          </h2>
          <h2>
            <span className="highlighted">Partage-nous</span> ton avis !
          </h2>
        </div>
        <p>
          Les sondages sont anonymisés. La connexion est requise simplement pour
          éviter les réponses multiples.
          <br />
          Les réponses obligatoires sont marquées par "*".
        </p>
        {id === "pre-use" && (
          <>
            <p id="credit-msg">
              <mark>Nous t'offrons un bonus,</mark> utilisable sur ton compte
              calshare si tu <mark>réponds à ce sondage,</mark> pour te
              permettre <mark>d'aller à un événement de ton choix</mark> plus
              facilement !
            </p>
            <p id="credit-condition">
              ({bonusAmount}€ valables seulement pour ton premier trajet en tant
              que passager)
            </p>
          </>
        )}
      </section>
      {forbidden ? (
        <section className="part-page" id="forbidden-msg">
          <h1>Hmm, on dirait que tu ne peut pas répondre à ce sondage :/</h1>
          <p>{forbidden}</p>
        </section>
      ) : hasSumitted ? (
        <section className="part-page" id="submitted-msg">
          <p id="thanks-word">
            Tes réponses ont été prises en compte. Merci pour ta participation à
            rendre Calshare meilleure pour tous ! 😉
          </p>
          <PrimaryButton icon="🏠" onClick={() => nav("/calshare")}>
            Accueil
          </PrimaryButton>
        </section>
      ) : (
        <>
          <section className="full-page" id="survey">
            {questions.map((question) => (
              <QuestionComponent
                key={question.id}
                question={question}
                onChange={(val) => updateValues(question.id, val)}
              />
            ))}
          </section>
          {error && <p id="error-msg">{error}</p>}
          <PrimaryButton enabled={!isPending} icon="📬" onClick={submit}>
            {isPending ? <Loading /> : "Envoyer"}
          </PrimaryButton>
        </>
      )}
    </>
  );
};

export default Survey;
