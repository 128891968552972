import { useEffect, useState } from "react";
import { auth } from "../firebase";
import Authenticate from "../pages/Authenticate";
import Loading from "./Loading";
import { useCurrentUser } from "../hooks";
import { useNavigate } from "react-router-dom";

interface Props {
  children: JSX.Element;
  role?: string;
}

const NeedAuthentication = ({ children, role }: Props) => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>();
  const [hasAccess, setHasAccess] = useState<boolean>();
  const user = useCurrentUser();
  const nav = useNavigate();

  useEffect(() => {
    auth.onAuthStateChanged((user) => {
      setIsAuthenticated(user?.uid !== undefined);
    });
  }, [setIsAuthenticated]);

  useEffect(() => {
    if (role) {
      setHasAccess(user.data?.roles?.includes(role));
    } else {
      setHasAccess(true);
    }
  }, [user, role]);

  if (role !== undefined && isAuthenticated === true && hasAccess === false) {
    nav("/");
  }

  return isAuthenticated === true && hasAccess === true ? (
    children
  ) : isAuthenticated === undefined ? (
    <div id="full-page-loading">
      <Loading />
      <h2>Chargement, on y est presque !</h2>
    </div>
  ) : (
    <>
      <p id="auth-message">Connecte-toi pour accèder à la page demandée</p>
      <Authenticate onAuthenticate={() => setIsAuthenticated(true)} />
    </>
  );
};

export default NeedAuthentication;
